import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import store from './utils/store';
export default {
  __name: 'App',
  setup(__props) {
    let day = new Date();
    store.commit('changeState', 'id', day.getTime());
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_component_router_view, {
        style: {
          "width": "100%",
          "height": "100%"
        }
      });
    };
  }
};