import "core-js/modules/es.array.push.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-898b9af4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "main-container"
};
const _hoisted_2 = {
  class: "x-header-wrap"
};
const _hoisted_3 = {
  class: "x-header"
};
const _hoisted_4 = {
  class: "left"
};
const _hoisted_5 = {
  class: "translation"
};
const _hoisted_6 = {
  class: "right"
};
const _hoisted_7 = ["src"];
import { onMounted, ref } from 'vue';
import { ElNotification } from 'element-plus';
import store from '../utils/store';
import axios from 'axios';
import router from '@/utils/router';
import LogoPosi from "@/components/LogoPosi.vue";
export default {
  __name: 'ChildFunc',
  setup(__props) {
    const languageMap = {
      en: {
        zhLan: 'Chinese',
        enLan: 'English',
        jpLan: 'Japan',
        choice: {
          source: 'Display Text',
          target: 'Display Translation'
        },
        back: 'Back',
        tips: {
          title: 'Tips',
          content: 'Please select the language you want to translate on the left'
        },
        tips2: {
          title: 'Loading',
          content: 'Loading,please wait!'
        },
        error: 'Server Error'
      },
      zh: {
        zhLan: '中文翻译',
        enLan: '英文翻译',
        jpLan: '日文翻译',
        choice: {
          source: '显示原文',
          target: '显示翻译'
        },
        back: '返回',
        tips: {
          title: '温馨提示',
          content: '请在左侧选择要翻译的语种'
        },
        tips2: {
          title: '加载中',
          content: '加载中，请稍后！'
        },
        error: '服务器出错'
      },
      jp: {
        zhLan: '中国語',
        enLan: '英語',
        jpLan: '日本語',
        choice: {
          source: 'テキストを表示',
          target: '翻訳を表示'
        },
        back: 'リターンマッチ',
        tips: {
          title: '暖かいヒント',
          content: '左側で翻訳する言語を選択してください'
        },
        tips2: {
          title: 'ロード中',
          content: 'ロード中、しばらくお待ちください'
        },
        error: 'サーバーでエラーが発生しました'
      }
    };
    const languageCode = ref(store.state.choiceLanguage);
    const isTranslation = ref(store.state.choiceLanguage === store.state.sourceLanguage ? 2 : 1);
    const languageResult = ref(languageMap[languageCode.value]);
    const pageName = getPageLan();
    const urlData = ref(store.state.baseUrl + store.state.currentSpiderPage + pageName.value);
    setTimeout(() => loadHostName(), 1500);
    function loadHostName() {
      if (!store.state.spiderHostName) {
        try {
          const _iframe_ = document.getElementsByTagName('iframe')[0];
          const doc = _iframe_.contentDocument;
          const _page_host_1_ = doc.querySelector('a[class="logo"], a[title="看漫画"], a[title="Manga Online"]');
          const _url1_ = new URL(_page_host_1_.href);
          store.commit("setSpiderHostName", _url1_.origin);
        } catch (e) {
          console.log(e);
          console.log("获取iframe域名失败");
        }
      } else {
        console.log("已存在源网页域名");
      }
    }
    function getPageLan() {
      let _cp_ = ref('/index.html');
      if (isTranslation.value === 1) {
        _cp_.value = `/trans(${languageCode.value}).html`;
      }
      console.log("当前页面语种为：", _cp_.value);
      return _cp_;
    }

    /**
     *
     * @param idx
     * 1:显示翻译
     * 2:显示原文
     */
    function onChangeUrlData(idx) {
      if (idx === 1) {
        const language = store.state.choiceLanguage;
        const sourceLanguage = store.state.sourceLanguage;
        if (language && language !== sourceLanguage) {
          languageResult.value = languageMap[language];
          languageCode.value = language;
          urlData.value = store.state.baseUrl + store.state.currentSpiderPage + `/trans(${language}).html`;
          // currentPage.value = `/trans(${language}).html`
        } else {
          ElNotification({
            title: languageResult.value.tips.title,
            message: languageResult.value.tips.content,
            type: 'warning'
          });
        }
      } else {
        isTranslation.value = idx;
        languageCode.value = store.state.sourceLanguage;
        languageResult.value = languageMap[store.state.sourceLanguage];
        urlData.value = store.state.baseUrl + store.state.currentSpiderPage + `/index.html`;
        // currentPage.value = '/index.html'
      }
    }
    function onLanguage(language) {
      languageCode.value = language;
      const sourceLan = store.state.sourceLanguage;
      if (language === sourceLan) {
        isTranslation.value = 2;
        urlData.value = store.state.baseUrl + store.state.currentSpiderPage + `/index.html`;
        // currentPage.value = '/index.html'
      } else {
        store.state.ifload = true;
        axios({
          method: 'POST',
          url: store.state.baseUrl + '/trans/api/v1',
          data: {
            path: store.state.currentSpiderPage,
            language: language,
            host: store.state.spiderHostName
          }
        }).then(response => {
          store.state.ifload = false;
          if (store.state.ifcancel) {
            store.state.ifcancel = false;
            return;
          }
          if (response['data'] == null) {
            ElNotification({
              title: 'error',
              message: languageResult.value['error'],
              type: 'error'
            });
          } else {
            if (response['data']['code'] === 200) {
              store.commit("changeLan", language);
              isTranslation.value = 1;
              urlData.value = store.state.baseUrl + store.state.currentSpiderPage + `/trans(${language}).html`;
            } else {
              ElNotification({
                message: response['data']['msg'],
                type: 'warning'
              });
            }
          }
        }).catch(() => {
          store.state.ifload = false;
          if (store.state.ifcancel) {
            store.state.ifcancel = false;
            return;
          }
        });
      }
      languageResult.value = languageMap[language];
    }
    store.state.ifload = true;
    onMounted(() => {
      let tag = document.getElementsByTagName('iframe')[0];
      tag.onload = function () {
        store.state.ifload = false;
        try {
          var iframeDoc = tag.contentDocument || tag.contentWindow.document;
          var links = iframeDoc.getElementsByTagName('a');
          for (var i = 0; i < links.length; i++) {
            links[i].addEventListener('click', function (event) {
              event.preventDefault();
              ElNotification({
                title: languageResult.value.tips2.title,
                message: languageResult.value.tips2.content,
                type: 'info'
              });
              let url = new URL(this.href);
              const ignoreOrigin = ['http://127.0.0.1:10101', 'https://t9.swdz.com'];
              if (ignoreOrigin.indexOf(url.origin) !== -1) {
                url = store.state.spiderHostName + url.pathname + url.search + url.hash;
                url = new URL(url);
              }
              getChapterUrl(url, iframeDoc);
              store.commit('funcChildData', url);
              store.state.ifload = true;
              axios({
                method: 'POST',
                url: store.state.baseUrl + '/spider/comic',
                data: {
                  url: url,
                  times: store.state.times,
                  language: languageCode.value
                }
              }).then(response => {
                store.state.ifload = false;
                if (store.state.ifcancel) {
                  store.state.ifcancel = false;
                  return;
                }
                if (response['data'] == null) {
                  ElNotification({
                    title: 'error',
                    message: languageResult.value['error'],
                    type: 'error'
                  });
                } else {
                  store.state.comicName = response['data']['title'];
                  store.state.comicChapter = response['data']['chapter'];
                  store.state.index = response['data']['index'];
                  store.commit('updataComic', response['data']['comic']);
                  store.state.times = response['data']['times'];
                  store.state.isTwo = response['data']['is_two'];
                  router.push('/readcomic');
                }
              }).catch(() => {
                store.state.ifload = false;
                if (store.state.ifcancel) {
                  store.state.ifcancel = false;
                  return;
                }
              });
            });
          }
        } catch (e) {
          console.error('Error accessing iframe content:', e);
        }
      };
    });
    function getChapterUrl(url, iframeDoc) {
      const manganto = ["https://manganato.com", "https://chapmanganato.to"];
      let all_links = [];
      if (url.origin === 'https://manga1000.cc') {
        const chapterDiv = iframeDoc.querySelectorAll('.chapter.col-xs-5');
        for (const per of chapterDiv) {
          const a_link = per.querySelector('a');
          all_links.push(a_link.href);
        }
      } else if (url.origin === 'https://www.manhuagui.com') {
        let chapterDiv = iframeDoc.querySelectorAll('#chapter-list-1')[0].querySelectorAll('ul');
        chapterDiv = Array.from(chapterDiv);
        chapterDiv.reverse();
        for (const per of chapterDiv) {
          const li_link = per.querySelectorAll('li');
          for (const li of li_link) {
            let a_link = li.querySelector('a').href;
            try {
              const a_href = new URL(a_link);
              const ignoreOrigin = ['http://127.0.0.1:10101', 'https://t9.swdz.com'];
              if (ignoreOrigin.indexOf(a_href.origin) !== -1) {
                a_link = store.state.spiderHostName + a_href.pathname + a_href.search + a_href.hash;
              }
            } catch (e) {
              console.log(e);
            }
            all_links.push(a_link);
          }
        }
      } else if (manganto.indexOf(url.origin) !== -1) {
        const chapterDiv = iframeDoc.querySelectorAll("li[class='a-h']");
        for (const per of chapterDiv) {
          const a_link = per.querySelector('a');
          all_links.push(a_link.href);
        }
      }
      const idx = all_links.indexOf(url.href);
      store.commit("setChapterUrls", all_links);
      store.commit("setCurrentChapterIdx", idx);
    }
    return (_ctx, _cache) => {
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_button = _resolveComponent("el-button");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", null, [_createVNode(LogoPosi, {
        style: {
          "height": "100px"
        }
      })]), _createElementVNode("div", _hoisted_5, [_createElementVNode("span", {
        class: _normalizeClass(languageCode.value === 'zh' ? 'span-active' : ''),
        onClick: _cache[0] || (_cache[0] = $event => onLanguage('zh'))
      }, _toDisplayString(languageResult.value.zhLan), 3), _createElementVNode("span", {
        class: _normalizeClass(languageCode.value === 'en' ? 'span-active' : ''),
        onClick: _cache[1] || (_cache[1] = $event => onLanguage('en'))
      }, _toDisplayString(languageResult.value.enLan), 3), _createElementVNode("span", {
        class: _normalizeClass(languageCode.value === 'jp' ? 'span-active' : ''),
        onClick: _cache[2] || (_cache[2] = $event => onLanguage('jp'))
      }, _toDisplayString(languageResult.value.jpLan), 3)])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_select, {
        modelValue: isTranslation.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => isTranslation.value = $event),
        onChange: onChangeUrlData
      }, {
        default: _withCtx(() => [_createVNode(_component_el_option, {
          label: languageResult.value.choice.target,
          value: 1
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(languageResult.value.choice.target), 1)]),
          _: 1
        }, 8, ["label"]), _createVNode(_component_el_option, {
          label: languageResult.value.choice.source,
          value: 2
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(languageResult.value.choice.source), 1)]),
          _: 1
        }, 8, ["label"])]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_el_button, {
        style: {
          "margin-left": "10px"
        },
        type: "primary",
        onClick: _cache[4] || (_cache[4] = $event => _unref(router).back())
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(languageResult.value.back), 1)]),
        _: 1
      })])])]), _withDirectives(_createElementVNode("iframe", {
        src: urlData.value
      }, null, 8, _hoisted_7), [[_directive_loading, _ctx.ifload]])]);
    };
  }
};