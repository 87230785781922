import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  style: {
    "width": "100rem",
    "height": "auto",
    "margin": "5rem auto",
    "border-radius": "1rem",
    "box-shadow": "0px  0px 1rem 1rem  #CDD0D6",
    "font-weight": "bold",
    "font-size": "large"
  }
};
const _hoisted_2 = {
  style: {
    "width": "40rem",
    "height": "5rem",
    "line-height": "5rem",
    "text-align": "center",
    "float": "left"
  }
};
const _hoisted_3 = {
  style: {
    "width": "58rem",
    "padding-left": "2rem",
    "height": "5rem",
    "line-height": "5rem",
    "text-align": "left",
    "float": "left"
  }
};
const _hoisted_4 = {
  style: {
    "width": "20rem",
    "height": "6rem",
    "margin": "2rem auto"
  }
};
import router from '@/utils/router';
import axios from 'axios';
import store from '@/utils/store';
import { ref } from 'vue';
import { ElNotification } from 'element-plus';
export default {
  __name: 'AdminPage',
  setup(__props) {
    let configdata = ref();
    let configName = {
      'web_trans_api': '网页翻译API',
      'web_trans_from_lang': '网页默认翻译源语言',
      'web_use_gpt_for_better_trans': '网页是否使用GPT进行辅助翻译',
      'web_spider_kind': '网页爬虫种类',
      'web_trans_to_lang': '网页翻译目标语言',
      'comic_ocr_api': '漫画文字识别API',
      'comic_trans_api': '漫画翻译API',
      'comic_from_lang': '漫画默认源语言',
      'comic_to_lang': '漫画默认目标语言',
      'comic_use_gpt_for_better_trans': '漫画是否使用GPT进行辅助翻译',
      'max_user_trans': '最大用户翻译图片数',
      'max_home_page_save_time_seconds': '最大主页保存时长(s)',
      'max_page_save_time_seconds': '最大页面保存时长(s)',
      'spider_page_refresh_time_seconds': '已爬网页的超时检测间隔(s)'
    };
    axios({
      method: 'GET',
      url: store.state.baseUrl + '/config/get'
    }).then(res => {
      configdata.value = res['data'];
    }).catch(() => {
      console.log('error');
    });
    const refresh = () => {
      axios({
        method: 'GET',
        url: store.state.baseUrl + '/config/get'
      }).then(res => {
        configdata.value = res['data'];
      }).catch(() => {
        console.log('error');
      });
    };
    const submitdata = () => {
      let returndata = configdata.value;
      returndata['max_user_trans'] = Number(returndata['max_user_trans']);
      console.log(configdata.value);
      axios({
        method: 'POST',
        url: store.state.baseUrl + '/config/change',
        data: returndata
      }).then(() => {
        ElNotification({
          type: 'success',
          title: '成功',
          message: '更改已完成'
        });
      }).catch(() => {
        ElNotification({
          type: 'error',
          title: '错误',
          message: '一些错误发生了'
        });
      });
    };
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_input = _resolveComponent("el-input");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_button, {
        type: "primary",
        onClick: _cache[0] || (_cache[0] = $event => _unref(router).back()),
        style: {
          "position": "absolute",
          "top": "0",
          "left": "0"
        }
      }, {
        default: _withCtx(() => [_createTextVNode("back")]),
        _: 1
      }), _createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(configdata), (value, key) => {
        return _openBlock(), _createElementBlock("div", {
          style: {
            "width": "100rem",
            "height": "5rem"
          },
          key: key
        }, [_createElementVNode("div", _hoisted_2, _toDisplayString(_unref(configName)[key]), 1), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_input, {
          modelValue: _unref(configdata)[key],
          "onUpdate:modelValue": $event => _unref(configdata)[key] = $event,
          style: {
            "width": "40rem"
          },
          placeholder: "Please input",
          disabled: key != 'max_user_trans'
        }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])])]);
      }), 128)), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_button, {
        type: "primary",
        onClick: refresh
      }, {
        default: _withCtx(() => [_createTextVNode("重置")]),
        _: 1
      }), _createVNode(_component_el_button, {
        type: "primary",
        onClick: submitdata
      }, {
        default: _withCtx(() => [_createTextVNode("提交")]),
        _: 1
      })])])]);
    };
  }
};