import "core-js/modules/es.array.push.js";
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6e897ed8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "main-container"
};
const _hoisted_2 = {
  class: "x-header-wrap"
};
const _hoisted_3 = {
  class: "x-header"
};
const _hoisted_4 = {
  class: "left"
};
const _hoisted_5 = {
  class: "translation"
};
const _hoisted_6 = {
  class: "right"
};
const _hoisted_7 = {
  class: "chapterChange"
};
const _hoisted_8 = {
  style: {
    "width": "100%",
    "height": "0",
    "overflow-y": "auto",
    "flex": "1"
  }
};
const _hoisted_9 = ["src"];
import router from '../utils/router';
import store from '@/utils/store';
import LogoPosi from '@/components/LogoPosi.vue';
import { ElNotification } from 'element-plus';
import { ref } from 'vue';
import axios from "axios";
export default {
  __name: 'ReadComic',
  setup(__props) {
    const languageMap = {
      en: {
        zhLan: 'Chinese',
        enLan: 'English',
        jpLan: 'Japan',
        choice: {
          source: 'Display Text',
          target: 'Display Translation'
        },
        back: 'Back',
        tips: {
          title: 'Tips',
          content: 'Please select the language you want to translate on the left'
        },
        tips2: {
          title: 'Loading',
          content: 'Loading,please wait!'
        },
        error: 'Server Error',
        prePage: '< Previous chapter',
        nextPage: 'Next chapter >',
        noMorePre: 'No More previous Chapter',
        noMoreNext: 'No More Next Chapter'
      },
      zh: {
        zhLan: '中文翻译',
        enLan: '英文翻译',
        jpLan: '日文翻译',
        choice: {
          source: '显示原文',
          target: '显示翻译'
        },
        back: '返回',
        tips: {
          title: '温馨提示',
          content: '请在左侧选择要翻译的语种'
        },
        tips2: {
          title: '加载中',
          content: '加载中，请稍后！'
        },
        error: '服务器出错',
        prePage: '< 上一章',
        nextPage: '下一章 >',
        noMorePre: '没有上一章了',
        noMoreNext: '没有下一章了'
      },
      jp: {
        zhLan: '中国語',
        enLan: '英語',
        jpLan: '日本語',
        choice: {
          source: 'テキストを表示',
          target: '翻訳を表示'
        },
        back: 'リターンマッチ',
        tips: {
          title: '暖かいヒント',
          content: '左側で翻訳する言語を選択してください'
        },
        tips2: {
          title: 'ロード中',
          content: 'ロード中、しばらくお待ちください'
        },
        error: 'サーバーでエラーが発生しました',
        prePage: '< 前の章',
        nextPage: '次の章へ >',
        noMorePre: '前の章はありません',
        noMoreNext: '次の章はありません'
      }
    };
    const languageCode = ref(store.state.choiceLanguage);
    const isTranslation = ref(store.state.choiceLanguage === store.state.sourceLanguage ? 2 : 1);
    const languageResult = ref(languageMap[languageCode.value]);
    const current_chapter_idx = ref(store.state.currentChapterIdx);
    const all_chapter_urls = store.state.allChapterUrls;
    function changeChapter(_t_) {
      store.state.ifload = true;
      let chapterIdx = current_chapter_idx.value;
      const chapterCnt = all_chapter_urls.length;
      if (_t_ === 'pre') {
        chapterIdx += 1;
      } else {
        chapterIdx -= 1;
      }
      if (chapterIdx === -1) {
        ElNotification({
          title: languageResult.value.tips.title,
          message: languageResult.value.noMoreNext,
          type: 'warning'
        });
        store.state.ifload = false;
      } else if (chapterIdx === chapterCnt) {
        ElNotification({
          title: languageResult.value.tips.title,
          message: languageResult.value.noMorePre,
          type: 'warning'
        });
        store.state.ifload = false;
      } else {
        const url = all_chapter_urls[chapterIdx];
        store.commit('funcChildData', url);
        store.state.ifload = true;
        axios({
          method: 'POST',
          url: store.state.baseUrl + '/spider/comic',
          data: {
            url: url,
            times: store.state.times,
            language: languageCode.value
          }
        }).then(response => {
          store.state.ifload = false;
          if (store.state.ifcancel) {
            store.state.ifcancel = false;
            return;
          }
          if (response['data'] == null) {
            ElNotification({
              title: 'error',
              message: languageResult.value['error'],
              type: 'error'
            });
          } else {
            store.state.comicName = response['data']['title'];
            store.state.comicChapter = response['data']['chapter'];
            store.state.index = response['data']['index'];
            store.commit('updataComic', response['data']['comic']);
            store.state.times = response['data']['times'];
            store.state.isTwo = response['data']['is_two'];
            const newComicUrl2 = getComicUrl();
            store.commit('updataComic', newComicUrl2);
            store.commit('setCurrentChapterIdx', chapterIdx);
            current_chapter_idx.value = chapterIdx;
          }
        }).catch(() => {
          store.state.ifload = false;
          if (store.state.ifcancel) {
            store.state.ifcancel = false;
            return;
          }
        });
      }
    }

    /**
     *
     * @param idx
     * 1:显示翻译
     * 2:显示原文
     */
    function onChangeUrlData(idx) {
      if (idx === 1) {
        const language = store.state.choiceLanguage;
        const sourceLanguage = store.state.sourceLanguage;
        if (language && language !== sourceLanguage) {
          languageResult.value = languageMap[language];
          languageCode.value = language;
          store.commit('changeTransState', language);
        } else {
          ElNotification({
            title: languageResult.value.tips.title,
            message: languageResult.value.tips.content,
            type: 'warning'
          });
        }
      } else {
        isTranslation.value = idx;
        languageCode.value = store.state.sourceLanguage;
        languageResult.value = languageMap[store.state.sourceLanguage];
        store.commit('changeTransState', -1);
      }
    }
    function onLanguage(language) {
      languageCode.value = language;
      const sourceLan = store.state.sourceLanguage;
      if (language === sourceLan) {
        isTranslation.value = 2;
        store.commit('changeTransState', -1);
      } else {
        axios({
          method: 'POST',
          url: store.state.baseUrl + '/trans/api/v1',
          data: {
            title: store.state.comicName,
            chapter: store.state.comicChapter,
            language: language
          }
        }).then(response => {
          store.state.ifload = false;
          if (store.state.ifcancel) {
            store.state.ifcancel = false;
            return;
          }
          if (response['data'] == null) {
            ElNotification({
              title: 'error',
              message: languageResult.value['error'],
              type: 'error'
            });
          } else {
            if (response['data']['code'] === 200) {
              store.commit("changeLan", language);
              isTranslation.value = 1;
              store.commit('changeTransState', language);
            } else {
              ElNotification({
                message: response['data']['msg'],
                type: 'warning'
              });
            }
          }
        }).catch(() => {
          store.state.ifload = false;
          if (store.state.ifcancel) {
            store.state.ifcancel = false;
            return;
          }
        });
      }
      languageResult.value = languageMap[language];
    }
    function getComicUrl() {
      let newComicUrl = [];
      for (let i = 0; i < store.state.comic.length; i++) {
        if (isTranslation.value === 2) {
          newComicUrl.push(store.state.baseUrl + '/static/comic/' + store.state.comicName + '/' + store.state.comicChapter + '/' + store.state.comic[i]);
        } else {
          const fns = store.state.comic[i].split('.');
          newComicUrl.push(store.state.baseUrl + '/static/comic/' + store.state.comicName + '/' + store.state.comicChapter + '/' + fns[0] + `(${languageCode.value}).` + fns[1]);
        }
      }
      return newComicUrl;
    }
    const newComicUrl1 = getComicUrl();
    store.commit('updataComic', newComicUrl1);

    // watch(showTrans, (trans) => {
    //   console.log('点击了翻译切换按钮', trans)
    //   store.commit('changeTransState', trans)
    // })

    return (_ctx, _cache) => {
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_button = _resolveComponent("el-button");
      const _directive_lazy = _resolveDirective("lazy");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", null, [_createVNode(LogoPosi, {
        style: {
          "height": "100px"
        }
      })]), _createElementVNode("div", _hoisted_5, [_createElementVNode("span", {
        class: _normalizeClass(languageCode.value === 'zh' ? 'span-active' : ''),
        onClick: _cache[0] || (_cache[0] = $event => onLanguage('zh'))
      }, _toDisplayString(languageResult.value.zhLan), 3), _createElementVNode("span", {
        class: _normalizeClass(languageCode.value === 'en' ? 'span-active' : ''),
        onClick: _cache[1] || (_cache[1] = $event => onLanguage('en'))
      }, _toDisplayString(languageResult.value.enLan), 3), _createElementVNode("span", {
        class: _normalizeClass(languageCode.value === 'jp' ? 'span-active' : ''),
        onClick: _cache[2] || (_cache[2] = $event => onLanguage('jp'))
      }, _toDisplayString(languageResult.value.jpLan), 3)])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_select, {
        modelValue: isTranslation.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => isTranslation.value = $event),
        onChange: onChangeUrlData
      }, {
        default: _withCtx(() => [_createVNode(_component_el_option, {
          label: languageResult.value.choice.target,
          value: 1
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(languageResult.value.choice.target), 1)]),
          _: 1
        }, 8, ["label"]), _createVNode(_component_el_option, {
          label: languageResult.value.choice.source,
          value: 2
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(languageResult.value.choice.source), 1)]),
          _: 1
        }, 8, ["label"])]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_el_button, {
        style: {
          "margin-left": "10px"
        },
        type: "primary",
        onClick: _cache[4] || (_cache[4] = $event => _unref(router).back())
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(languageResult.value.back), 1)]),
        _: 1
      })])]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_el_button, {
        type: "primary",
        onClick: _cache[5] || (_cache[5] = $event => changeChapter('pre'))
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(languageResult.value.prePage), 1)]),
        _: 1
      }), _createVNode(_component_el_button, {
        type: "primary",
        onClick: _cache[6] || (_cache[6] = $event => changeChapter('next'))
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(languageResult.value.nextPage), 1)]),
        _: 1
      })])]), _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(store).state.comic.length, i => {
        return _openBlock(), _createElementBlock("li", {
          key: i
        }, [_withDirectives(_createElementVNode("img", {
          class: "imgdata",
          src: _unref(store).state.comic[i - 1]
        }, null, 8, _hoisted_9), [[_directive_lazy, _unref(store).state.comic[i - 1]]])]);
      }), 128))])])), [[_directive_loading, _unref(store).state.ifload]])]);
    };
  }
};