import { createApp } from 'vue'
import App from './App.vue'
import router from './utils/router.js'
import ElementPlus from 'element-plus'
import VueCookies from 'vue3-cookies'
import store from './utils/store'
import 'element-plus/dist/index.css'
import lazyPlugin from "vue3-lazy"

const app=createApp(App)
app.use(router)
app.use(ElementPlus)
app.use(VueCookies)
app.use(store)
app.use(lazyPlugin, {
    loading: require('@/assets/loading.gif'),
    error: require('@/assets/loading.jpg')
})
app.mount('#app')
