import {createRouter,createMemoryHistory} from 'vue-router'

import LoginPage from'../pages/users/LoginPage'
import FuncPage from '../pages/FuncPage.vue'
import HomePage from '../pages/HomePage.vue'
import RegisterPage from '@/pages/users/RegisterPage'
import ReadComic from '../pages/ReadComic.vue'
import HomeFunc from '../components/HomeFunc'
import ChildFunc from '../components/ChildFunc'
import AdminPage from '../pages/AdminPage'

const router=createRouter({
    history:createMemoryHistory(),
    routes:[
        {
            path:'/',
            redirect:'/home'
        },
        {
            path:'/login',
            component:LoginPage
        },
        {
            path:'/func',
            component:FuncPage,
            children:[
                {
                    path:'home',
                    component:HomeFunc,
                    meta:{
                        keepAlive:true
                    }
                },
                {
                    path:'child',
                    component:ChildFunc,
                    meta:{
                        keepAlive:true
                    }
                }
            ]
        },
        {
            path:'/home',
            component:HomePage
        },
        {
            path:'/register',
            component:RegisterPage
        },
        {
            path:'/readcomic',
            component:ReadComic
        },
        {
            path:'/admin',
            component:AdminPage
        }
    ]
})

export default router