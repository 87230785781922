import {createStore} from "vuex"


const store = createStore({
    state() {
        return {
            userLang: 'English',
            baseUrl: '',//ly change
            currentChapter: '',
            funcChild: '',
            data: {},
            comic: [],
            tempComic: [],
            times: 0,
            index: 0,
            id: 0,
            currentSpiderPage: '',
            currentSpiderUrl: '',
            firstSpiderPage: '',
            firstSpiderUrl: '',
            comicName: '',
            comicChapter: '',
            ifload: false,
            ifcancel: false,
            isTwo: false,
            sourceLanguage: '',  // 网页的原始语言
            choiceLanguage: '',  // 用户当前选择的语言
            spiderHostName: '',  // 当前爬取页面的域名
            currentChapterIdx: '',  // 当前章节的索引值
            allChapterUrls: [],  // 所有章节的链接
        }
    },
    mutations: {
        funcChildData(state, newFuncChild) {
            state.funcChild = newFuncChild
        },
        changeState(state, name, newData) {
            if (name == 'index') {
                state.index = newData
            } else if (name == 'times') {
                state.times = newData
            } else if (name == 'id') {
                state.id = newData
            }
        },
        changeData(state, newData) {
            state.data = newData
        },
        updataComic(state, newcomic) {
            state.comic = newcomic
        },
        current(state, newCurrent) {
            state.currentChapter = newCurrent
        },
        changeTransState(state, trans) {
            //todo 此处更改了文件位置，将漫画图片加入后缀(tran)
            if (trans !== -1) {
                for (let i = 0; i < state.index; i++) {
                    console.log(state.comic[i])
                    let arr = state.comic[i].split('/')
                    let path = arr.slice(0, arr.length - 1).join('/')
                    let f = arr[arr.length - 1]
                    if (f.indexOf('(') !== -1) {
                        f = f.replace('(', '').replace(')', '').replace('en', '').replace('jp', '').replace('zh', '')
                    }
                    let fn = f.split('.')

                    fn[0] = `${fn[0]}(${trans})`
                    fn = fn.join('.')
                    state.comic[i] = path + '/' + fn
                    console.log(state.comic[i])
                }
                for (let i = state.index; i < state.comic.length; i++) {
                    state.tempComic.push(state.comic[i])
                    state.comic[i] = state.baseUrl + '/homepage/notimes.png'
                }
                console.log(state.comic)
            } else {
                for (let i = 0; i < state.index; i++) {
                    console.log(state.comic[i])
                    let arr = state.comic[i].split('/')
                    let path = arr.slice(0, arr.length - 1).join('/')
                    let fn = arr[arr.length - 1].replace('(', '').replace(')', '').replace('en', '').replace('jp', '').replace('zh', '')
                    state.comic[i] = path + '/' + fn
                }
                for (let i = state.index; i < state.comic.length; i++) {
                    state.comic[i] = state.tempComic[i - state.index]
                }
                state.tempComic.length = 0
            }
        },
        setSourceLan(state, language) {
            state.sourceLanguage = language
        },
        changeLan(state, language) {
            state.choiceLanguage = language
        },
        setSpiderHostName(state, host) {
            state.spiderHostName = host
        },
        setChapterUrls(state, urls) {
            state.allChapterUrls = urls
        },
        setCurrentChapterIdx(state, idx) {
            state.currentChapterIdx = idx
        }
    }
})

export default store